/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useMemo, useRef } from 'react';
import { useSearch } from 'wouter';

import useDocument from '../../hooks/useDocument';
import * as AppPropTypes from '../../lib/PropTypes';

import { formats as defaultFormats, variations as defaultVariations } from '../../data/share';
import DocumentFeaturedCard from '../cards/DocumentFeaturedCard';

import styles from '../../styles/pages/document-share-image-page.module.css';

const propTypes = {
    slug: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    variation: PropTypes.string.isRequired,
    current: PropTypes.bool,
    entered: PropTypes.bool,
    entering: PropTypes.bool,
    leaving: PropTypes.bool,
    disabled: PropTypes.bool,
    formats: PropTypes.arrayOf(AppPropTypes.shareFormat),
    variations: PropTypes.arrayOf(AppPropTypes.shareVariation),
    className: PropTypes.string,
    contentClassName: PropTypes.string,
};

const defaultProps = {
    formats: defaultFormats,
    variations: defaultVariations,
    current: false,
    entered: false,
    entering: false,
    leaving: false,
    disabled: false,
    className: null,
    contentClassName: null,
};
function DocumentShareImagePage({
    slug,
    format: formatId,
    variation: variationId,
    formats,
    variations,
    entered,
    entering,
    leaving,
    current,
    disabled,
    className,
    contentClassName,
}) {
    const {
        document: currentDocument = null,
        // notFound = false,
        // isFetching = false,
    } = useDocument(slug, {
        snippetOnly: true,
        keepPreviousData: false,
        enabled: !disabled,
    });

    const search = useSearch();
    const {
        x: queryX = null,
        y: queryY = null,
        scale: queryScale = null,
        labelBackground = null,
        textColor = null,
        ...queryProps
    } = useMemo(() => queryString.parse(search), [search]);

    const { width, height } = formats.find(({ id }) => id === formatId) || formats[0];
    const {
        id,
        label,
        scale: variationScale = null,
        ...props
    } = variations.find(({ id: currentId }) => currentId === variationId) || variations[0];

    const scale = queryScale !== null ? parseFloat(queryScale) : variationScale;
    const positionX = queryX !== null ? parseFloat(queryX) : 0.5;
    const positionY = queryY !== null ? parseFloat(queryY) : 0.5;

    const ref = useRef(null);
    useEffect(() => {
        ref.current.style.setProperty('--card-label-background', labelBackground);
        ref.current.style.setProperty('--card-color', textColor);
    }, [labelBackground, textColor])

    return (
        <div className={classNames([styles.container, className, contentClassName])}>
            <div
                className={styles.frame}
                style={{
                    width,
                    height,
                }}
                ref={ref}
            >
                <div
                    className={styles.scale}
                    style={
                        scale !== null
                            ? {
                                  width: Math.round(width / scale),
                                  height: Math.round(height / scale),
                                  transform: scale ? `scale(${scale})` : null,
                                  transformOrigin: 'top left',
                              }
                            : null
                    }
                >
                    <DocumentFeaturedCard
                        theme="over-bottom-left"
                        withUrbaniaLogo
                        withoutAuthorBox
                        withoutDate
                        withoutAuthorImage
                        {...props}
                        {...currentDocument}
                        {...Object.keys(queryProps).reduce((map, key) => {
                            let val = queryProps[key];
                            if (val === 'false') {
                                val = false;
                            } else if (val === 'true') {
                                val = true;
                            }
                            return {
                                ...map,
                                [key]: queryProps[key],
                            };
                        }, {})}
                        className={styles.card}
                        pictureStyle={{
                            objectPosition: `${positionX * 100}% ${positionY * 100}%`,
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

DocumentShareImagePage.propTypes = propTypes;
DocumentShareImagePage.defaultProps = defaultProps;

export default DocumentShareImagePage;
