export const formats = [
    {
        id: 'instagram',
        label: 'Instagram',
        width: 1080,
        height: 1350,
    },
    {
        id: 'facebook',
        label: 'Facebook',
        width: 1200,
        height: 800,
    },
];

export const variations = [
    {
        id: 'over',
        label: 'Par dessus',
        theme: 'over-bottom-left',
        responsive: true,
        scale: 2,
        // size: 'big',
    },
    {
        id: 'box',
        label: 'Dans une boîte',
        theme: 'box-bottom-left',
        responsive: true,
        scale: 2.5,
        // size: 'big',
    },
];

export const utmSources = [
    {
        label: 'Journaliste',
        value: 'journaliste',
    },
    {
        label: 'Employés URBANIA',
        value: 'employes_urbania',
    },
    {
        label: 'RP',
        value: 'rp',
    },
    {
        label: 'Contact Direct',
        value: 'contact_direct',
    },
    {
        label: 'Partenariat Rémunéré',
        value: 'partenariat_remunere',
    },
    {
        label: 'ManyChat',
        value: 'manychat',
    },
];

export const utmMediums = [
    {
        label: 'Partage',
        value: 'partage',
    },
];
