import * as Components from './components';

import meia from './img/drinks/meia.png';
import rum from './img/drinks/rum.png';
import tonic from './img/drinks/tonic.png';
import wine from './img/drinks/wine.png';
import boreal from './img/pastilles/boreal.png';
import delicat from './img/pastilles/delicat.png';
import fruite from './img/pastilles/fruite.png';
import vanille from './img/pastilles/vanille.png';

export default {
    id: 'cdpq',
    title: 'Quiz CDPQ',
    components: Components,

    theme: {
        name: 'cdpq',
    },

    getResult: (answers) => {
        const values = Object.keys(answers).reduce((acc, key) => {
            const { answerValue = null } = answers[key] || {};
            if (typeof acc[answerValue] === 'undefined') {
                acc[answerValue] = 0;
            }
            acc[answerValue] += 1;
            return acc;
        }, {});
        const winner = Object.keys(values).reduce((acc, key) => {
            const points = values[key];
            const { points: previousPoints = 0 } = acc || {};
            if (points >= previousPoints) {
                return { points, key };
            }
            return acc;
        }, 0);

        const { key = null } = winner || {};

        return { rank: key };
    },

    slides: [
        {
            type: 'Start',
            texts: {
                button: 'Commencer le quiz',
            },
        },
        {
            type: 'Question',
            qid: 1,
            texts: {
                title: '<p>Enfin un long week-end à l’horizon! Vous planifiez…</p>',
            },
            answers: [
                {
                    value: 'B',
                    label: '… un week-end avec des amis dans un chalet de la région de Charlevoix.',
                    description: '<p>Les montagnes, c’est comme votre deuxième maison. ⛰️</p>',
                    isBoolean: false,
                },
                {
                    value: 'V',
                    label: '… une soirée entre ami.e.s pour potiner sur la dernière bisbille à OD.',
                    description: '<p>Le <em>tea</em>? Votre raison de vivre.</p>',
                    isBoolean: false,
                },
                {
                    value: 'D',
                    label: '… ne rien faire – et ça vous remplit de bonheur, #joyofmissingout.',
                    description: '<p>Votre pyjama et votre série chouchou vous appellent!</p>',
                    isBoolean: false,
                },
                {
                    value: 'F',
                    label: '… une sortie entre ami.e.s dans un bar branché qui vient tout juste d’ouvrir. ',
                    description:
                        '<p>À vos yeux, il n’y a rien comme amorcer sa soirée en se mettant <em>cute</em> avec du <em>vino</em> mousseux et en <em>vibant</em> avec sa <em>playlist</em> préférée. 😌</p>',
                    isBoolean: false,
                },
            ],
        },
        {
            type: 'Question',
            qid: 2,
            texts: {
                title: '<p>L’album que vous écoutez sans arrêt (et sans culpabilité) est :</p>',
            },
            answers: [
                {
                    value: 'V',
                    label: '<em>Short n’ Sweet</em> de Sabrina Carpenter.',
                    description:
                        '<p>Parce que la vie est trop courte pour écouter de la musique triste.</p>',
                    isBoolean: false,
                },
                {
                    value: 'D',
                    label: '<em>La nuit est une panthère</em> des Louanges.',
                    description:
                        '<p>La musique un peu plus <em>underground</em>, c’est votre tasse de thé.</p>',
                    isBoolean: false,
                },
                {
                    value: 'F',
                    label: '<em>ten days</em> de Fred again..',
                    description:
                        '<p>Toujours à la recherche d’action, vous rechargez vos batteries avec de l’électro avant vos prochaines aventures.</p>',
                    isBoolean: false,
                },
                {
                    value: 'B',
                    label: '<em>In Rainbows</em> de Radiohead.',
                    description:
                        '<p>Vous êtes la personne désignée pour choisir les chansons lors d’un <em>road trip</em> et vous en êtes très fier.ère.</p>',
                    isBoolean: false,
                },
            ],
        },
        {
            type: 'Question',
            qid: 3,
            texts: {
                title: '<p>En voyage, vous êtes la personne qui…</p>',
            },
            answers: [
                {
                    value: 'D',
                    label: '… êtes le parent du groupe. ',
                    description:
                        '<p>Passeport? On l’a. Crème solaire? Aussi. Carte d’embarquement? Déjà dans le sac. Étant la personne la plus organisée du groupe, vous rappelez constamment à vos ami.e.s d’apporter leurs essentiels de voyage.</p>',
                    isBoolean: false,
                },
                {
                    value: 'B',
                    label: '… prend les photos les plus mémorables de vos escapades.',
                    description:
                        '<p>Avec votre nouvel appareil photo argentique, vous vous assurez d’en capturer les plus beaux moments. PS – Demandez donc à vos ami.e.s de prendre une ou deux photos de vous afin qu’on vous voit aussi dans le carrousel photo du voyage… </p>',
                    isBoolean: false,
                },
                {
                    value: 'V',
                    label: '… perd son passeport et oublie tout. ',
                    description:
                        '<p>Un peu dans la lune, vous oubliez parfois des articles assez importants, et ça vous cause des ennuis… C’est correct, on vous aime comme ça!</p>',
                    isBoolean: false,
                },
                {
                    value: 'F',
                    label: '… se fond dans la foule des habitants du coin et trouve les meilleurs endroits à visiter.',
                    description:
                        '<p>Vous parlez probablement cinq langues et vous vous liez facilement d’amitié avec tout le monde, ce qui permet à votre groupe d’ami.e.s. de visiter les endroits les plus <em>hot</em> en ville.</p>',
                    isBoolean: false,
                },
            ],
        },
        {
            type: 'Question',
            qid: 4,
            texts: {
                title: '<p>Sur votre bucket list, on trouve :</p>',
            },
            answers: [
                {
                    value: 'F',
                    label: 'Être aux premières loges d’un concert de Charli XCX.',
                    description: '<p>Votre place est sur la piste de danse, c’est certain!</p>',
                    isBoolean: false,
                },
                {
                    value: 'D',
                    label: 'Apprendre à faire des pâtes maison en Italie.',
                    description:
                        '<p>Vous seriez bientôt maître des pâtes et pourriez organiser des soirées dans votre appartement sur le Plateau!</p>',
                    isBoolean: false,
                },
                {
                    value: 'B',
                    label: 'Fonder un groupe de musique et faire une tournée au Québec.',
                    description:
                        '<p>Les soirées autour du feu avec votre guitare vous ont déjà bien préparé.e pour ce moment.</p>',
                    isBoolean: false,
                },
                {
                    value: 'V',
                    label: 'Ouvrir un refuge pour animaux.',
                    description:
                        '<p>Avec votre fibre entrepreneuriale et votre énergie, vous avez tout pour réaliser vos rêves de venir en aide aux animaux.</p>',
                    isBoolean: false,
                },
            ],
        },
        {
            type: 'Question',
            qid: 5,
            texts: {
                title: '<p>Le prochain voyage que vous souhaitez faire est :</p>',
            },
            answers: [
                {
                    value: 'B',
                    label: 'Un voyage de ski au Japon.',
                    description:
                        '<p>Vous êtes toujours à l’affût de nouvelles destinations de rêve où skier, ça, c’est clair!</p>',
                    isBoolean: false,
                },
                {
                    value: 'F',
                    label: 'Une escapade à Berlin pour danser dans une boîte de nuit.',
                    description:
                        '<p>Vous avez certainement les meilleures anecdotes de voyage à raconter quand vous revenez de vacances.</p>',
                    isBoolean: false,
                },
                {
                    value: 'D',
                    label: 'Une retraite d’écriture aux îles Lofoten.',
                    description:
                        '<p>Quoi de mieux que des paysages nordiques pour se laisser aller sur le clavier?</p>',
                    isBoolean: false,
                },
                {
                    value: 'V',
                    label: 'Un voyage 100 % plage en Australie.',
                    description:
                        '<p>Voir des kangourous et se faire bronzer sur la plage? Vacances de rêve!</p>',
                    isBoolean: false,
                },
            ],
        },
        {
            type: 'Ending',
            texts: {
                body: null,
            },
            results: {
                B: {
                    title: 'Forestier boréal',
                    body: '<p>Fan de la nature et de sports d’aventure, vous trouverez votre compte parmi les prêts-à-boire à base de gin qui se boivent bien dehors après une longue journée à bouger en plein air.</p><p>On recommande : <a href="https://www.saq.com/fr/15200291?utm_campaign=inspire-genz-2024-2025&utm_medium=content&utm_source=urbania&utm_content=in-article-gin-tonic-fr" target="_blank">1642 & Km12 Gin Tonic</a></p>',
                    image: tonic,
                    topImage: boreal,
                },
                D: {
                    title: 'Délicat et léger',
                    body: '<p>Comme vous cherchez toujours le confort avant tout, il n’y a rien que vous aimez plus que de siroter un bon vin lors d’un repas cozy entre ami.e.s ou pour accompagner votre moment de <em>journaling</em>.</p><p>On recommande : <a href="https://www.saq.com/fr/12332301?utm_campaign=inspire-genz-2024-2025&utm_medium=content&utm_source=urbania&utm_content=in-article-meia-encosta-dao-fr" target="_blank">Meia Encosta Dão</a></p>',
                    image: meia,
                    topImage: delicat,
                },
                F: {
                    title: 'Fruité et vif',
                    body: '<p>Vous êtes l’âme de la fête et vous savez mettre de la vie autour de vous et avoir du plaisir! Une bonne bouteille de mousseux pour accompagner le tout, c’est le parfait combo.</p><p>Et ça adonne aussi que vous maîtrisez assez bien le sabre… Pratique, quand on a une bouteille de mousseux entre les mains! 😌</p><p>On recommande : <a href="https://www.saq.com/fr/106492?utm_campaign=inspire-genz-2024-2025&utm_medium=content&utm_source=urbania&utm_content=in-article-cuvee-brute-fr" target="_blank">Hungaria Grande Cuvée Brut</a></p>',
                    image: wine,
                    topImage: fruite,
                },
                V: {
                    title: 'Vanillé et épicé',
                    body: '<p>Vous êtes une personne proche de ses émotions et vous aimez rassembler vos ami.e.s pour leur faire vivre les plus belles aventures. Vos histoires ont une bonne dose de piquant et vos cocktails aussi.</p><p>On recommande : <a href="https://www.saq.com/fr/500512?utm_campaign=inspire-genz-2024-2025&utm_medium=content&utm_source=urbania&utm_content=in-article-captain-morgan-fr" target="_blank">Captain Morgan Original Spiced</a></p>',
                    image: rum,
                    topImage: vanille,
                },
            },
        },
    ],
};
